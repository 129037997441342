import React, { useState, useEffect } from "react";

import { Form, message, Button, Space, InputNumber } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import _ from "lodash-es";

import { SetSettlementUser, GetSettlementUser } from "../../api";
import { gameRouter, allGameRouter } from "../../functions/public_variable";

// const { Option } = Select;

export default ({ game, id }) => {
    const [state, setstate] = useState({});
    const [formdata, set_formdata] = useState({});
    // let a = {
    //     status: "SUCCESS",
    //     code: 200,
    //     msg: [
    //         {
    //             _id: "88423688b2c31d885be5272c4203a8d8",
    //             game_id: "5b1f3a3cb76a591e7f25173",
    //             id: 778024442,
    //             proxy_user_id: 351027469,
    //             settlement_percent: 0,
    //             grade_value: null,
    //             grade_percent: null,
    //         },
    //     ],
    // };
    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (id) {
    //             let res = await GetSettlementUser(id);
    //             if (res.code === 200) {
    //                 message.success("获取分红比例成功");
    //             } else {
    //                 message.error(`获取分红比例失败${JSON.stringify(res)}`);
    //             }
    //         }
    //     };
    // }, []);
    const onFinish = async (values) => {
        console.log(values);
        let grade_value = [],
            grade_percent = [];
        if (values.rate) {
            values.rate.forEach((ele) => {
                grade_value.push(ele.grade_value);
                grade_percent.push(ele.grade_percent);
            });
        }
        grade_value = JSON.stringify(_.orderBy(grade_value));
        grade_percent = JSON.stringify(_.orderBy(grade_percent));
        console.log(
            gameRouter?.[game]?.child,
            values.settlement_percent,
            grade_value,
            grade_percent,
        );
        //如果是传进来的是game,则是全局分红设置,去修改这一类游戏下的所有子游戏
        //如果没有传game而是传id,则去修改该id下所有子游戏
        let target;
        if (game) {
            target = gameRouter[game].child;
        } else {
            target = allGameRouter;
        }
        //批量修改分红比例
        let promises = [];
        _.forEach(target, async (v, k) => {
            promises.push(
                new Promise(async (reslove, reject) => {
                    let res = await SetSettlementUser({
                        game_id: k,
                        settlement_percent: values.settlement_percent,
                        grade_value,
                        grade_percent,
                        id: id || 0,
                    });
                    if (res.code === 200) {
                        reslove();
                    } else {
                        reject(res);
                    }
                }),
            );
        });
        Promise.all(promises)
            .then((result) => {
                message.success("操作成功");
            })
            .catch((error) => {
                message.error(`操作失败${error.msg}`);
            });
    };
    const formonchange = (changedValues, allValues) => {
        setstate(allValues);
    };
    return (
        <>
            <div style={{ color: "red", fontWeight: 700 }}>
                若是流水金额未在档位金额值区间中, 则默认为100%档位比
            </div>
            <br />
            <Form onFinish={onFinish} onValuesChange={formonchange}>
                {/* {game && (
                <Form.Item
                name={gametype}
            >
                <Select placeholder="分红方式">
                    <Option value={1}>按流水</Option>
                    <Option value={2}>按亏损</Option>
                </Select>
            </Form.Item>
            )} */}
                <Form.Item name="settlement_percent" label="分红百分比(默认0%)" initialValue={0}>
                    <InputNumber
                        style={{ width: 100 }}
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace("%", "")}
                    />
                </Form.Item>
                <Form.List name="rate">
                    {(fields, { add, remove }) => {
                        return (
                            <div>
                                {fields.map((field) => (
                                    <Space
                                        key={field.key}
                                        style={{ display: "flex", marginBottom: 8 }}
                                        align="start"
                                    >
                                        <Form.Item
                                            {...field}
                                            name={[field.name, "grade_value"]}
                                            fieldKey={[field.fieldKey, "grade_value"]}
                                            rules={[{ required: true, message: "请填写档位金额" }]}
                                            label="档位金额(小于) : "
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, "grade_percent"]}
                                            fieldKey={[field.fieldKey, "grade_percent"]}
                                            rules={[{ required: true, message: "请填写档位比" }]}
                                            label="档位比 : "
                                            initialValue={0}
                                        >
                                            <InputNumber
                                                style={{ width: 100 }}
                                                min={0}
                                                max={100}
                                                formatter={(value) => `${value}%`}
                                                parser={(value) => value.replace("%", "")}
                                            />
                                        </Form.Item>

                                        <MinusCircleOutlined
                                            onClick={() => {
                                                remove(field.name);
                                            }}
                                        />
                                    </Space>
                                ))}

                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add();
                                        }}
                                        block
                                    >
                                        <PlusOutlined /> 增添档位
                                    </Button>
                                </Form.Item>
                            </div>
                        );
                    }}
                </Form.List>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
            {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
        </>
    );
};
