import React, { useState } from "react";

import { Card, Statistic, Row, Col } from "antd";

import AgentComponent from "./agentComponent";

export default () => {
    const [agentnumber, setagentnumber] = useState(0);
    const account_name = parseInt(localStorage.getItem("id"));
    return (
        <Card>
            <Row gutter={[16, 16]} style={{ background: "#ececec", padding: 30 }}>
                <Col span={8}>
                    <Card style={{ textAlign: "center" }}>
                        <Statistic
                            title="上级ID"
                            value={localStorage.proxy_pid}
                            groupSeparator=""
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card style={{ textAlign: "center" }}>
                        <Statistic title="直属下级玩家数" value={localStorage.direct_number} />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card style={{ textAlign: "center" }}>
                        <Statistic title="代理链总玩家数" value={agentnumber} />
                    </Card>
                </Col>
            </Row>
            <AgentComponent
                id={account_name}
                topDistance={20}
                setagentnumber={setagentnumber}
                level={1}
            />
        </Card>
    );
};
