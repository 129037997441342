import React, { useState, useEffect } from "react";

import { Button, message, Card, Table, Modal, InputNumber } from "antd";

import {
    getChildren,
    getAggregation,
    getAgentIncome,
    getGameUserIncome,
    transfermoney,
} from "../../api";
import { formatDate, reserveDecimal } from "../../functions/reverseFunctions";
import AgentComponent from "./agentComponent";
import Proxychaindata from "./proxychaindata";
// import { MyDatePicker } from "../../components/Mycomponents";

let prepare_data = [];
export default ({ id, topDistance, setagentnumber, level }) => {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [is_next_level_show, set_is_next_level_show] = useState(false);
    const [is_proxychaindata_show, set_is_proxychaindata_show] = useState(false);
    const [cid, set_cid] = useState(false);
    const [proxychaindata_id, set_proxychaindata_id] = useState(false);

    // const timerange = useRef(null);
    const account_name = parseInt(localStorage.getItem("id"));
    const fetchData = async (page, limit) => {
        const res = await getChildren(page, limit, account_name, id);
        if (res.code === 200 && res.msg) {
            prepare_data = [];
            let ids = res.msg.map((ele) => {
                prepare_data.push({ ...ele, sum_income: 0, agent_number: 0 });
                return ele.id;
            });
            //第三列数据
            const result = await getAggregation(account_name, ids);
            if (result.code === 200 && result.msg) {
                prepare_data.forEach((item) => {
                    result.msg.forEach((ele) => {
                        if (item.id === ele.id) {
                            //因为result.msg只包含带有下级的客户，遍历data和result.msg进行比较
                            item.agent_number += ele.children && ele.children.proxy_level_1;
                        }
                    });
                });
            }
            //第四列数据
            const res1 = await getAgentIncome(1, limit, account_name, ids, "");
            if (res1.code === 200 && res1.msg) {
                prepare_data.forEach((item) => {
                    res1.msg.forEach((ele) => {
                        if (item.id === ele.proxy_user_id) {
                            item.sum_income += ele.statement_income * 0.35;
                        }
                    });
                });
            }
            const res2 = await getGameUserIncome(1, limit, account_name, ids, "");
            if (res2.code === 200 && res2.msg) {
                prepare_data.forEach((item) => {
                    res2.msg.forEach((ele) => {
                        if (item.id === ele.game_user_id) {
                            item.sum_income += ele.statement_income;
                        }
                    });
                });
            }
            setData(prepare_data);
        } else {
            message.error(res.msg || "请求失败");
        }
    };
    const fetch_agent_number = async () => {
        const res = await getAggregation(account_name, id);
        if (res.code === 200 && res.msg && res.msg[0].children) {
            setCount(res.msg[0].children.proxy_level_1);
            if (setagentnumber) {
                let num = 0;
                for (const key in res.msg[0].children) {
                    num += res.msg[0].children[key];
                }
                setagentnumber(num);
            }
        }
    };
    useEffect(() => {
        fetch_agent_number();
        fetchData(1, 20);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const check_agent = (record) => {
        if (record.agent_number === 0) {
            message.error("该代理没有下级");
            return;
        }
        set_is_next_level_show(true);
        set_cid(record.id);
    };
    const checkproxychaindata = (id) => {
        set_proxychaindata_id(id);
        set_is_proxychaindata_show(true);
    };
    const transfer_money = (record) => {
        let inputvalue = null;
        const changeInput = (val) => {
            inputvalue = val;
        };
        const handleOKBtn = async () => {
            const res = await transfermoney(inputvalue, record.id);
            if (res.code === 200) {
                localStorage.balance = res.msg.balance;
                message.success(`转账成功,您的余额为${res.msg.balance.toFixed(2)}`);
            } else {
                message.error("转账失败");
            }
        };
        Modal.confirm({
            title: "转账",
            okText: "确定",
            cancelText: "取消",
            content: (
                <>
                    <div>您的佣金余额&nbsp;:&nbsp;{localStorage.balance}</div>
                    <br />
                    <div>用户&nbsp;:&nbsp;{record.id}</div>
                    <br />
                    <div>
                        请输入转账金额&nbsp;:&nbsp;&nbsp;&nbsp;
                        <InputNumber
                            style={{ width: 200 }}
                            onChange={(val) => changeInput(val)}
                        ></InputNumber>
                    </div>
                </>
            ),
            width: "40%",
            onOk: handleOKBtn,
        });
    };
    const initColumns = [
        {
            title: "邀请时间",
            dataIndex: "regin_time",
            render: formatDate,
        },
        {
            title: "玩家ID",
            dataIndex: "id",
        },
        {
            title: "下级人数",
            dataIndex: "agent_number",
        },
        {
            title: "累计贡献佣金",
            dataIndex: "sum_income",
            render: reserveDecimal,
        },
        {
            title: "操作",
            dataIndex: "",
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" size="small" onClick={() => check_agent(record)}>
                            查看下级
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => checkproxychaindata(record.id)}
                        >
                            代理链数据
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {level === 1 && (
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => transfer_money(record)}
                            >
                                转账
                            </Button>
                        )}
                    </>
                );
            },
        },
    ];
    return (
        <Card>
            {level === 1 && (
                <div>
                    当前代理ID代理链数据: &nbsp;&nbsp;&nbsp;&nbsp;
                    {/* <MyDatePicker
                        handleValue={(date, dateString) => {
                            timerange.current = {
                                start_time: date[0]?.valueOf(),
                                end_time: date[1]?.valueOf(),
                            };
                        }}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp; */}
                    <Button
                        type="primary"
                        onClick={() => {
                            checkproxychaindata(account_name);
                        }}
                    >
                        查询
                    </Button>
                    <br />
                    <br />
                </div>
            )}
            <Table
                bordered
                size="small"
                rowKey={(record, index) => `${index}`}
                dataSource={data}
                columns={initColumns}
                pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: 20,
                    showQuickJumper: true,
                    showSizeChanger: false,
                    showTotal: (total, range) => `共${total}条`,
                    total: count,
                    onChange: (page, pageSize) => {
                        fetchData(page, pageSize);
                    },
                }}
            />
            {is_next_level_show && (
                <Modal
                    title={`[ID:${cid}]直属下级列表`}
                    visible={is_next_level_show}
                    style={{ top: topDistance + 40 }}
                    onCancel={() => {
                        set_is_next_level_show(false);
                    }}
                    footer={null}
                    mask={false}
                    maskClosable={false}
                    width="60%"
                >
                    <AgentComponent id={cid} topDistance={topDistance + 40} level={level + 1} />
                </Modal>
            )}
            {is_proxychaindata_show && (
                <Modal
                    title={`[ID:${proxychaindata_id}]代理链数据`}
                    visible={is_proxychaindata_show}
                    onCancel={() => {
                        set_is_proxychaindata_show(false);
                    }}
                    footer={null}
                    mask={false}
                    maskClosable={false}
                    width="60%"
                    style={{ top: 10 }}
                >
                    <Proxychaindata
                        id={proxychaindata_id}
                        // timerange={timerange.current}
                    />
                </Modal>
            )}
        </Card>
    );
};
