import React, { useState, useEffect } from "react";

import { Button, message, Card, Table, Statistic, Row, Col, Modal } from "antd";

import {
    getChildren,
    getAggregation,
    getIncome,
    getAgentIncome,
    getGameUserIncome,
} from "../../api";
import { reserveDecimal } from "../../functions/reverseFunctions";

export default () => {
    const [data, setData] = useState([]);
    const [allincome, setallincome] = useState("0.00");
    const [todayincome, set_todayincome] = useState(0);
    const [isdetailshow, set_isdetailshow] = useState(false);
    const [date, setDate] = useState();

    const [detailData, set_detailData] = useState([]);

    const account_name = parseInt(localStorage.getItem("id"));

    useEffect(() => {
        const fetchData = async () => {
            //历史总佣金
            const res = await getAggregation(account_name, account_name);
            if (res.code === 200 && res.msg && res.msg[0].history_income) {
                let num = 0;
                for (const key in res.msg[0].history_income) {
                    num += res.msg[0].history_income[key];
                }
                setallincome(reserveDecimal(num));
            } else {
                message.error(res.msg || JSON.stringify(res));
            }
            //每日流水
            const result = await getIncome(1, 30, account_name, "");
            if (result.code === 200 && result.msg) {
                setData(result.msg);
                //渲染top表格的今日佣金和今日流水
                let today = new Date();
                let month = today.getMonth() + 1;
                let day = today.getDate();
                let mydate = result.msg[0].date.split("-").map(Number);
                console.log("返回数据第一组的日期：", mydate, month, day);
                if (mydate[1] === month && mydate[2] === day) {
                    set_todayincome(reserveDecimal(result.msg[0].statement_income));
                }
            }
        };
        fetchData();
    }, [account_name]);
    const fetch_detailData = async (page, dat) => {
        let prepare_data = [];
        const res = await getChildren(page, 20, account_name, account_name);
        if (res.code === 200 && res.msg) {
            let ids = res.msg.map((ele) => {
                prepare_data.push({ ...ele, sum_income: 0, agent_number: 0 });
                return ele.id;
            });
            const res1 = await getAgentIncome(1, 20, account_name, ids, dat);
            if (res1.code === 200 && res1.msg) {
                prepare_data.forEach((item) => {
                    res1.msg.forEach((ele) => {
                        if (item.id === ele.proxy_user_id) {
                            item.sum_income += ele.statement_income * 0.35;
                        }
                    });
                });
            }
            const res2 = await getGameUserIncome(1, 20, account_name, ids, dat);
            if (res2.code === 200 && res2.msg) {
                prepare_data.forEach((item) => {
                    res2.msg.forEach((ele) => {
                        if (item.id === ele.game_user_id) {
                            item.sum_income += ele.statement_income;
                        }
                    });
                });
            }
            set_detailData(prepare_data);
        }
    };
    const check_details = async (dat) => {
        setDate(dat);
        set_isdetailshow(true);
        //按日查询数据
        fetch_detailData(1, dat);
    };
    const initColumns = [
        {
            title: "日期",
            dataIndex: "date",
        },
        {
            title: "流水",
            dataIndex: "statement_income",
			render: (text,record) => {
                if (record.date < "2020-06-30") return reserveDecimal(text / 0.05 / 0.5 / 0.45);
                if (record.date >= "2020-06-30") return reserveDecimal(text / 0.05 / 0.5 / 0.35);
            },
        },
        {
            title: "佣金比例",
            dataIndex: "",
            render: (text, record) => {
                if (record.date < "2020-06-30") return "45%";
                if (record.date >= "2020-06-30") return "35%";
            },
        },
        {
            title: "佣金",
            dataIndex: "statement_income",
            render: reserveDecimal,
        },
        {
            title: "操作",
            dataIndex: "date",
            render: (text) => {
                return (
                    <Button type="primary" size="small" onClick={() => check_details(text)}>
                        查看详情
                    </Button>
                );
            },
        },
    ];
    return (
        <Card>
            <Row gutter={[16, 16]} style={{ background: "#ececec", padding: 30 }}>
                <Col span={8}>
                    <Card>
                        <Statistic
                            title="今日流水"
                            value={reserveDecimal(todayincome / 0.05 / 0.5 / 0.35)}
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                        <Statistic title="今日佣金" value={todayincome} />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                        <Statistic title="历史总佣金" value={allincome} />
                    </Card>
                </Col>
            </Row>
            <Table
                bordered
                size="small"
                rowKey={(record, index) => `${index}`}
                dataSource={data}
                columns={initColumns}
                pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: 30,
                    showSizeChanger: false,
                }}
            />
            {isdetailshow && (
                <Modal
                    title={`${date}佣金来源`}
                    visible={isdetailshow}
                    onCancel={() => set_isdetailshow(false)}
                    footer={null}
                    width="60%"
                >
                    <Table
                        bordered
                        size="small"
                        rowKey={(record, index) => `${index}`}
                        dataSource={detailData}
                        columns={[
                            {
                                title: "时间",
                                dataIndex: "",
                                render: () => date,
                            },
                            {
                                title: "直属玩家ID",
                                dataIndex: "id",
                            },
                            {
                                title: "佣金贡献",
                                dataIndex: "sum_income",
                                render: reserveDecimal,
                            },
                        ]}
                        pagination={{
                            defaultCurrent: 1,
                            defaultPageSize: 20,
                            total: localStorage.direct_number,
                            showSizeChanger: false,
                            showQuickJumper: true,
                            showTotal: (total, range) => `共${total}条`,
                            onChange: (page) => {
                                fetch_detailData(page, date);
                            },
                        }}
                    />
                </Modal>
            )}
        </Card>
    );
};
