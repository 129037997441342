import moment from "moment";

export function formatDate(time) {
	if (!time) return "";
	let timeStamp = parseInt(time);
	if (timeStamp === 0) return "";
	return moment(timeStamp * 1000).format("YYYY-MM-DD HH:mm:ss");
}
export function reserveDecimal(num) {
	if (!num) return "0.00";
	var newnum = num.toFixed(7);
	return (Math.floor(newnum * 100) / 100).toFixed(2);
}
