import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { createHashHistory } from "history";
import { Layout } from "antd";

import LeftNav from "../../components/left-nav";
import Header from "../../components/header";
import Admin from "../admin";
import NotFound from "../[not-found]/not-found";

import Agent from "../myagent";
import Income from "../income";
import Distribution from "../distribution";

import { Provider, KeepAlive } from "react-keep-alive";
const { Sider, Content } = Layout;

const history = createHashHistory();

export default function Rounter(props) {
    const [menukey, setMenukey] = useState();
    const token = localStorage.token;
    // 如果内存没有存储token ==> 当前没有登陆
    if (!token) {
        return <Redirect to="/login" />;
    }
    return (
        <Layout style={{ minHeight: "100%" }}>
            <Sider>
                <LeftNav
                    menu_onclick={(key) => {
                        setMenukey(key);
                    }}
                />
            </Sider>
            <Layout>
                <Header menukey={menukey} history={history} />
                <Content style={{ margin: 10, backgroundColor: "#fff", borderRadius: 5 }}>
                    <Provider>
                        <div style={{ height: "100%" }}>
                            <Switch>
                                <Redirect from="/" exact to="/admin" />
                                <Route path="/admin" exact>
                                    <Admin />
                                </Route>
                                <Route path="/myagent" exact>
                                    <KeepAlive name="admin">
                                        <Agent />
                                    </KeepAlive>
                                </Route>
                                <Route path="/income" exact>
                                    <KeepAlive name="income">
                                        <Income />
                                    </KeepAlive>
                                </Route>
                                <Route path="/distribution" exact>
                                    <KeepAlive name="distribution">
                                        <Distribution />
                                    </KeepAlive>
                                </Route>

                                <Route component={NotFound} />
                            </Switch>
                        </div>
                    </Provider>
                </Content>
                {/* <Footer style={{ textAlign: "center", color: "#cccccc", padding: 5 }}>
                    推荐使用谷歌浏览器，可以获得更佳页面操作体验
                </Footer> */}
            </Layout>
        </Layout>
    );
}
