//公共变量
export const gameRouter = {
    chesscardGame: {
        name: "棋牌类子游戏",
        child: {
            "5b1f3a3cb76a591e7f251721": { path: "/baijl/api", name: "百家乐" },
            "5c6a62be56209ac117d446aa": { path: "/jbpconsole/api", name: "聚宝盆捕鱼" },
            "5b1f3a3cb76a591e7f2517a6": { path: "/haiwangbuyu/api", name: "海王捕鱼" },
            "5b1f3a3cb76a591e7f251719": { path: "/redblackwar/api", name: "红黑大战" },
            "5b1f3a3cb76a591e7f251716": { path: "/bcbm/api", name: "奔驰宝马" },
            "5b1f3a3cb76a591e7f251718": { path: "/bairennn/api", name: "百人牛牛" },
            "5b1f3a3cb76a591e7f251717": { path: "/go_lhd/api", name: "龙虎斗" },
            "5b1f3a3cb76a591e7f251713": { path: "/lunpan/api", name: "轮盘" },
            "5b1f3a3cb76a591e7f251712": { path: "/shuigj/api", name: "水果机" },
            "5b1f3a3cb76alkje7f25170": { path: "/hongbaosl/api", name: "红包扫雷" },
            "5c6a62be7ff587m117d446aa": { path: "/hongbaold/api", name: "红包乱斗" },
            "5b1f3a3cb76a591e7f251720": { path: "/ebgconsole/api", name: "二八杠" },
            "5b1f3a3cb76a591e7f251711": { path: "/landlord/api", name: "斗地主" },
            "5b1f3a3cb76a591e7f251715": { path: "/zhajh/api", name: "炸金花" },
            "5b1f3a3cb76a591e7f251714": { path: "/qznn/api", name: "抢庄牛牛" },
            "5b1f3a3cb76a591e7f25171": { path: "/shisanshui/api", name: "十三水" },
            "5b1f3a3cb76a591e7f251722": { path: "/21d/api", name: "21点" },
            "5b1f3a3cb76a591e7f25170": { path: "/ermj/api", name: "二人麻将" },
            "5b1f3a3cb76a591e7f25176": { name: "德州扑克" },
            "5c6a62be7ff09a54amb446aa": { name: "跑得快" },
            "5b1f3a3cb76a591e7f251724": { name: "骰宝" },
            "5b1f3a3cb76a591e7f251725": { name: "狮子王国" },
        },
    },
    videoGame: {
        name: "视讯类子游戏",
        child: { "5b1f3a3cb76a591e7f25173": { name: "真人视讯" } },
    },
    caipiaoGame: {
        name: "彩票类子游戏",
        child: { "569a62be7ff123m117d446aa": { name: "派彩" } },
    },
    shabaGame: {
        name: "沙巴子游戏",
        child: { "5b1f3a3cb76a591e7f25179": { name: "沙巴体育" } },
    },
};
export const allGameRouter = {
    "5b1f3a3cb76a591e7f251721": { path: "/baijl/api", name: "百家乐" },
    "5c6a62be56209ac117d446aa": { path: "/jbpconsole/api", name: "聚宝盆捕鱼" },
    "5b1f3a3cb76a591e7f2517a6": { path: "/haiwangbuyu/api", name: "海王捕鱼" },
    "5b1f3a3cb76a591e7f251719": { path: "/redblackwar/api", name: "红黑大战" },
    "5b1f3a3cb76a591e7f251716": { path: "/bcbm/api", name: "奔驰宝马" },
    "5b1f3a3cb76a591e7f251718": { path: "/bairennn/api", name: "百人牛牛" },
    "5b1f3a3cb76a591e7f251717": { path: "/go_lhd/api", name: "龙虎斗" },
    "5b1f3a3cb76a591e7f251713": { path: "/lunpan/api", name: "轮盘" },
    "5b1f3a3cb76a591e7f251712": { path: "/shuigj/api", name: "水果机" },
    "5b1f3a3cb76alkje7f25170": { path: "/hongbaosl/api", name: "红包扫雷" },
    "5c6a62be7ff587m117d446aa": { path: "/hongbaold/api", name: "红包乱斗" },
    "5b1f3a3cb76a591e7f251720": { path: "/ebgconsole/api", name: "二八杠" },
    "5b1f3a3cb76a591e7f251711": { path: "/landlord/api", name: "斗地主" },
    "5b1f3a3cb76a591e7f251715": { path: "/zhajh/api", name: "炸金花" },
    "5b1f3a3cb76a591e7f251714": { path: "/qznn/api", name: "抢庄牛牛" },
    "5b1f3a3cb76a591e7f25171": { path: "/shisanshui/api", name: "十三水" },
    "5b1f3a3cb76a591e7f251722": { path: "/21d/api", name: "21点" },
    "5b1f3a3cb76a591e7f25170": { path: "/ermj/api", name: "二人麻将" },
    "5b1f3a3cb76a591e7f25176": { name: "德州扑克" },
    "5c6a62be7ff09a54amb446aa": { name: "跑得快" },
    "5b1f3a3cb76a591e7f251724": { name: "骰宝" },
    "5b1f3a3cb76a591e7f251725": { name: "狮子王国" },
    "5b1f3a3cb76a591e7f25173": { name: "真人视讯" },
    "569a62be7ff123m117d446aa": { name: "派彩" },
    "5b1f3a3cb76a591e7f25179": { name: "沙巴体育" },
};
export const allgameid = {
    chesscardGame: [
        "5b1f3a3cb76a591e7f251721",
        "5c6a62be56209ac117d446aa",
        "5b1f3a3cb76a591e7f2517a6",
        "5b1f3a3cb76a591e7f251719",
        "5b1f3a3cb76a591e7f251716",
        "5b1f3a3cb76a591e7f251718",
        "5b1f3a3cb76a591e7f251717",
        "5b1f3a3cb76a591e7f251713",
        "5b1f3a3cb76a591e7f251712",
        "5b1f3a3cb76alkje7f25170",
        "5c6a62be7ff587m117d446aa",
        "5b1f3a3cb76a591e7f251720",
        "5b1f3a3cb76a591e7f251711",
        "5b1f3a3cb76a591e7f251715",
        "5b1f3a3cb76a591e7f251714",
        "5b1f3a3cb76a591e7f25171",
        "5b1f3a3cb76a591e7f251722",
        "5b1f3a3cb76a591e7f25170",
        "5b1f3a3cb76a591e7f25176",
        "5c6a62be7ff09a54amb446aa",
        "5b1f3a3cb76a591e7f251724",
        "5b1f3a3cb76a591e7f251725",
    ],
    videoGame: ["5b1f3a3cb76a591e7f25173"],
    caipiaoGame: ["569a62be7ff123m117d446aa"],
    shabaGame: ["5b1f3a3cb76a591e7f25179"],
};
export const gameRouter2 = {
    chesscardGame: {
        key: "chesscardGame",
        name: "棋牌类子游戏",
        child: [
            { game_id: "5b1f3a3cb76a591e7f251721", path: "/baijl/api", name: "百家乐" },
            { game_id: "5c6a62be56209ac117d446aa", path: "/jbpconsole/api", name: "聚宝盆捕鱼" },
            { game_id: "5b1f3a3cb76a591e7f2517a6", path: "/haiwangbuyu/api", name: "海王捕鱼" },
            { game_id: "5b1f3a3cb76a591e7f251719", path: "/redblackwar/api", name: "红黑大战" },
            { game_id: "5b1f3a3cb76a591e7f251716", path: "/bcbm/api", name: "奔驰宝马" },
            { game_id: "5b1f3a3cb76a591e7f251718", path: "/bairennn/api", name: "百人牛牛" },
            { game_id: "5b1f3a3cb76a591e7f251717", path: "/go_lhd/api", name: "龙虎斗" },
            { game_id: "5b1f3a3cb76a591e7f251713", path: "/lunpan/api", name: "轮盘" },
            { game_id: "5b1f3a3cb76a591e7f251712", path: "/shuigj/api", name: "水果机" },
            { game_id: "5b1f3a3cb76alkje7f25170", path: "/hongbaosl/api", name: "红包扫雷" },
            { game_id: "5c6a62be7ff587m117d446aa", path: "/hongbaold/api", name: "红包乱斗" },
            { game_id: "5b1f3a3cb76a591e7f251720", path: "/ebgconsole/api", name: "二八杠" },
            { game_id: "5b1f3a3cb76a591e7f251711", path: "/landlord/api", name: "斗地主" },
            { game_id: "5b1f3a3cb76a591e7f251715", path: "/zhajh/api", name: "炸金花" },
            { game_id: "5b1f3a3cb76a591e7f251714", path: "/qznn/api", name: "抢庄牛牛" },
            { game_id: "5b1f3a3cb76a591e7f25171", path: "/shisanshui/api", name: "十三水" },
            { game_id: "5b1f3a3cb76a591e7f251722", path: "/21d/api", name: "21点" },
            { game_id: "5b1f3a3cb76a591e7f25170", path: "/ermj/api", name: "二人麻将" },
            { game_id: "5b1f3a3cb76a591e7f25176", name: "德州扑克" },
            { game_id: "5c6a62be7ff09a54amb446aa", name: "跑得快" },
            { game_id: "5b1f3a3cb76a591e7f251724", name: "骰宝" },
            { game_id: "5b1f3a3cb76a591e7f251725", name: "狮子王国" },
        ],
    },
    videoGame: {
        key: "videoGame",
        name: "视讯类子游戏",
        child: [{ game_id: "5b1f3a3cb76a591e7f25173", name: "真人视讯" }],
    },
    caipiaoGame: {
        key: "caipiaoGame",
        name: "彩票类子游戏",
        child: [{ game_id: "569a62be7ff123m117d446aa", name: "派彩" }],
    },
    shabaGame: {
        key: "shabaGame",
        name: "沙巴子游戏",
        child: [{ game_id: "5b1f3a3cb76a591e7f25179", name: "沙巴体育" }],
    },
};
