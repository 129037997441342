import React, { useState, useEffect, useRef } from "react";

import { Input, Button, message, Form, Checkbox, Select, Table, Modal, Popconfirm } from "antd";
import _ from "lodash-es";

import {
    AddSettlementGame,
    GetSettlementGame,
    DelSettlementGame,
    SetSettlementGame,
} from "../../api";
import { gameRouter } from "../../functions/public_variable";
import DistributionSetting from "./distribution_setting";

const { Option } = Select;

export default ({ is_tablebtn_clicked, id }) => {
    const [data, setdata] = useState([]);
    const [state, setstate] = useState({});
    const [is_set_distribution_rate_show, set_is_set_distribution_rate_show] = useState(false);
    const ref = useRef({ record: null });
    // const formRef = React.createRef();
    const fetchData = async () => {
        let res = await GetSettlementGame();
        if (res.code === 200) {
            let arr = [];
            let str = JSON.stringify(res.msg);
            if (_.includes(str, "5b1f3a3cb76a591e7f251721")) {
                arr.push({
                    key: "chesscardGame",
                    game: "棋牌类子游戏",
                    type: _.find(res.msg, { game_id: "5b1f3a3cb76a591e7f251721" })?.type,
                });
            }
            if (_.includes(str, "5b1f3a3cb76a591e7f25173")) {
                arr.push({
                    key: "videoGame",
                    game: "视讯类子游戏",
                    type: _.find(res.msg, { game_id: "5b1f3a3cb76a591e7f25173" })?.type,
                });
            }
            if (_.includes(str, "569a62be7ff123m117d446aa")) {
                arr.push({
                    key: "caipiaoGame",
                    game: "彩票类子游戏",
                    type: _.find(res.msg, { game_id: "569a62be7ff123m117d446aa" })?.type,
                });
            }
            if (_.includes(str, "5b1f3a3cb76a591e7f25179")) {
                arr.push({
                    key: "shabaGame",
                    game: "沙巴子游戏",
                    type: _.find(res.msg, { game_id: "5b1f3a3cb76a591e7f25179" })?.type,
                });
            }
            console.log(arr);
            setdata(arr);
        } else {
            message.error(JSON.stringify(res));
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    //增删改都需要用到的群发请求
    // const promiseAll=(request,respond)=>{}
    const onFinish = async (values) => {
        console.log(values);
        _.forEach(values, (value, key) => {
            if (_.includes(JSON.stringify(data), key)) value.is_check = false;
            if (value.is_check) {
                if (!value.settlement_type) {
                    message.error("请选择分红方式");
                    return;
                }
                let promises = [];
                _.forEach(gameRouter[key].child, async (v, k) => {
                    promises.push(
                        new Promise(async (reslove, reject) => {
                            let res = await AddSettlementGame(k, value.settlement_type);
                            if (res.code === 200) {
                                reslove();
                            } else {
                                reject(res);
                            }
                        }),
                    );
                });
                Promise.all(promises)
                    .then((result) => {
                        message.success("操作成功");
                        fetchData();
                    })
                    .catch((error) => {
                        message.error(`操作失败${error.msg}`);
                    });
            }
        });
    };
    const deletegame = async (record) => {
        let promises = [];
        _.forEach(gameRouter[record.key].child, async (v, k) => {
            promises.push(
                new Promise(async (reslove, reject) => {
                    let res = await DelSettlementGame(k);
                    if (res.code === 200) {
                        reslove();
                    } else {
                        reject(res);
                    }
                }),
            );
        });
        Promise.all(promises)
            .then((result) => {
                message.success("操作成功");
                fetchData();
            })
            .catch((error) => {
                message.error(`操作失败${error.msg}`);
            });
    };
    const setgame = async (record) => {
        let new_value;
        Modal.confirm({
            title: "修改分红方式",
            content: (
                <Select
                    placeholder="分红方式"
                    onChange={(value) => {
                        new_value = value;
                    }}
                    defaultValue={record.type}
                >
                    <Option value={1}>按流水</Option>
                    <Option value={2}>按亏损</Option>
                </Select>
            ),
            onOk() {
                let promises = [];
                _.forEach(gameRouter[record.key].child, async (v, k) => {
                    promises.push(
                        new Promise(async (reslove, reject) => {
                            let res = await SetSettlementGame(k, new_value);
                            if (res.code === 200) {
                                reslove();
                            } else {
                                reject(res);
                            }
                        }),
                    );
                });
                Promise.all(promises)
                    .then((result) => {
                        message.success("操作成功");
                        fetchData();
                    })
                    .catch((error) => {
                        message.error(`操作失败${error.msg}`);
                    });
                // let res = await SetSettlementGame(record.game_id, new_value);
                // if (res.code === 200) {
                //     message.success("修改成功");
                //     fetchData();
                // } else {
                //     message.error(JSON.stringify(res));
                // }
            },
        });
    };
    const setrate = async (record) => {
        ref.current.record = record;
        set_is_set_distribution_rate_show(true);
    };
    const FormOnChange = (changedValues, allValues) => {
        console.log(allValues);
        setstate(allValues);
    };
    const initColumns = [
        {
            title: "游戏",
            dataIndex: "game",
            // render: (text, record) => gameRouter?.[text].name,
        },
        {
            title: "分红方式",
            dataIndex: "type",
            render: (text, record) => (text === 1 ? "按流水" : "按亏损"),
        },
        // {
        //     title: "直属下级代理链玩家总输",
        //     dataIndex: "lose_total",
        // },
        // {
        //     title: "代理链玩家总流水",
        //     dataIndex: "",
        //     render: (text, record) => record.win_total + Math.abs(record.lose_total),
        // },
        {
            title: "操作",
            dataIndex: "",
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" size="small" onClick={() => setrate(record)}>
                            分红比例设置
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {!is_tablebtn_clicked && (
                            <>
                                <Button type="primary" size="small" onClick={() => setgame(record)}>
                                    编辑
                                </Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Popconfirm
                                    title="确定要删除吗?"
                                    onConfirm={() => deletegame(record)}
                                    okText="删除"
                                    cancelText="取消"
                                >
                                    <Button type="danger" size="small">
                                        删除
                                    </Button>
                                </Popconfirm>
                            </>
                        )}
                    </>
                );
            },
        },
    ];
    return (
        <>
            {!is_tablebtn_clicked && (
                <Form onFinish={onFinish} onValuesChange={FormOnChange} size="small">
                    {_.map(gameRouter, (value, key) => {
                        return (
                            <Form.Item key={key}>
                                <Input.Group compact>
                                    <Form.Item
                                        name={[key, "is_check"]}
                                        valuePropName="checked"
                                        style={{ margin: 0 }}
                                    >
                                        <Checkbox>{value.name}</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name={[key, "settlement_type"]}
                                        style={{
                                            margin: 0,
                                            display: state?.[key]?.is_check ? "" : "none",
                                        }}
                                        // hidden={!state?.[key]?.is_check}
                                    >
                                        <Select placeholder="分红方式">
                                            <Option value={1}>按流水</Option>
                                            <Option value={2}>按亏损</Option>
                                        </Select>
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        );
                    })}
                    <Form.Item style={{ display: "block" }}>
                        <Button type="primary" htmlType="submit">
                            确定增添
                        </Button>
                    </Form.Item>
                </Form>
            )}

            <Table
                bordered
                size="small"
                rowKey="key"
                dataSource={data}
                columns={initColumns}
                pagination={false}
            />
            {is_set_distribution_rate_show && (
                <Modal
                    title={`分红比例设置`}
                    visible={is_set_distribution_rate_show}
                    onCancel={() => set_is_set_distribution_rate_show(false)}
                    footer={null}
                    width="60%"
                    mask
                    maskClosable={false}
                >
                    <DistributionSetting game={ref.current.record.key} id={id} />
                </Modal>
            )}
        </>
    );
};
