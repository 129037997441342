import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

import { Input, Button, message, Form } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

// import ChooseURL from "../../components/chooseURL";
import { reqLogin, setToken, raceURL } from "../../api";
import "./login.css";

export default function Login(props) {
	useEffect(() => {
		raceURL();
	}, []);
	const formRef = React.createRef();
	//点击登录按钮
	const onFinish = async (values) => {
		// 对所有表单字段进行检验
		const result = await reqLogin(values);
		if (result.code === 200) {
			message.success("登陆成功");
			localStorage.token = result.msg.token;
			localStorage.id = result.msg.proxy_user.id;
			localStorage.proxy_nick = result.msg.proxy_user.proxy_nick;
			localStorage.proxy_pid = result.msg.proxy_user.proxy_pid;
			localStorage.direct_number = result.msg.proxy_user.direct_number;
			localStorage.balance = result.msg.proxy_user.balance;
			setToken();
			props.history.replace("/");
		} else {
			message.error("账号或密码错误" + JSON.stringify(result));
		}
	};
	// 如果用户已经登陆, 自动跳转到home界面
	const token = localStorage.token;
	if (token) {
		return <Redirect to="/admin" />;
	}

	return (
		<div className="login">
			<header className="login-header">
				<h1>&nbsp;&nbsp;&nbsp;&nbsp;全民代理系统</h1>
				{/* <div className="login-dropdown" /> */}
			</header>
			<section className="login-content">
				<h2>用户登陆</h2>
				<Form
					onFinish={onFinish}
					className="login-form"
					ref={formRef}
					size="large"
					// initialValues={{ account_name: 319010216, password: 123456 }}//pre总代
					// initialValues={{ account_name: 189454346, password: 123456 }}
					// initialValues={{ account_name: 351027469, password: 123456 }} //dev
				>
					<Form.Item
						name="account_name"
						rules={[
							{
								required : true,
								message  : "请输入用户名",
							},
						]}
					>
						<Input
							prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
							placeholder="用户名"
						/>
					</Form.Item>
					<Form.Item
						name="password"
						rules={[
							() => ({
								validator(rule, value) {
									if (value) {
										return Promise.resolve();
									} else {
										return Promise.reject("请输入密码");
									}
								},
							}),
						]}
					>
						<Input
							prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
							type="password"
							placeholder="密码"
						/>
					</Form.Item>
					<Form.Item style={{ marginTop: 50 }}>
						<Button type="primary" htmlType="submit" className="login-form-button">
							登陆
						</Button>
					</Form.Item>
				</Form>
			</section>
		</div>
	);
}
