import axios from "axios";
import { message } from "antd";

//设置axios为form-data
export default function ajax(url, data = {}, type = "GET", dataType) {
    const instance = axios.create()
    instance.interceptors.request.use(
        config => {
            // const token = localStorage.token;
            // if (token) {
            // 判断是否存在token，如果存在的话，则每个http header都加上token
            // config.headers["Authorization"] = token;
            // }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );
    return new Promise((resolve, reject) => {
        let promise;
        // 1. 执行异步ajax请求
        if (type === "GET") {
            promise = instance.get(url, {
                params: data // 指定请求参数
            });
        } else {
            instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
            instance.defaults.transformRequest = [
                function (data) {
                    let ret = "";
                    for (let it in data) {
                        ret +=
                            encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
                    }
                    return ret;
                }
            ];
            promise = instance.post(url, data);
        }
        // 2. 如果成功了, 调用resolve(value)
        promise.then(response => {
            if (response.data.code === 404 && response.data.msg === "Token is not match") {
                message.error("你的账号在别处登陆了")
                localStorage.removeItem("token");
                localStorage.removeItem("BASE");
                setTimeout(() => {
                    window.location.href = "/"
                }, 1000);   
            }
            resolve(response.data);
        })
            // 3. 如果失败了, 不调用reject(reason), 而是提示异常信息
            .catch(error => {
                // reject(error)
                message.error(
                    (error.response && error.response.data.msg) ||
                    "请求出错了: " + error.message
                );
            });

    });
}
