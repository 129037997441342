import React, { useState } from "react";

import { Card, message, Row, Col, Statistic, Table, Button } from "antd";
import _ from "lodash-es";

import { getProxychainData } from "../../api";
import { reverseNumber } from "../../functions/numberfn";
import { MyDatePicker } from "../../components/Mycomponents";

// let initstate = {
//     start_time: null,
//     end_time: null,
// };
export default ({ id, timerange }) => {
    const [msg, setMsg] = useState();
    const [times, setTimes] = useState({
        start_time: timerange?.start_time,
        end_time: timerange?.end_time,
    });
    const [data, setData] = useState([
        {
            name: "棋牌流水数据",
        },
        {
            name: "视讯流水数据",
        },
        {
            name: "彩票流水数据",
        },
        {
            name: "沙巴流水数据",
        },
        {
            name: "合计",
        },
    ]);
    const initColumns = [
        {
            title: "游戏",
            dataIndex: "name",
        },
        {
            title: "玩家总赢流水",
            dataIndex: "win_statement_total",
            render: reverseNumber,
        },
        {
            title: "玩家总输流水",
            dataIndex: "lose_statement_total",
            render: reverseNumber,
        },
        {
            title: "玩家总输 - 玩家总赢",
            dataIndex: "",
            render: (text, record) => {
                let result = Math.abs(record.lose_statement_total) - record.win_statement_total;
                if (!isNaN(result) && (record.win_statement_total || record.lose_statement_total)) {
                    return reverseNumber(result);
                }
            },
        },
        {
            title: "总流水",
            dataIndex: "",
            render: (text, record) => {
                let result = record.win_statement_total + Math.abs(record.lose_statement_total);
                if (!isNaN(result) && (record.win_statement_total || record.lose_statement_total)) {
                    return reverseNumber(result);
                }
            },
        },
    ];
    const proxySearch = async () => {
        let { start_time, end_time } = times;
        if (!start_time || !end_time) {
            message.error("请选择时间范围");
            return;
        }
        const res = await getProxychainData(
            id,
            Math.floor(start_time / 1000),
            Math.floor(end_time / 1000),
        );
        if (res.code === 200 && res.msg) {
            message.success(res.status);
            setMsg(res.msg);
            let totalwin = 0,
                totallose = 0;
            if (res.msg.game) {
                res.msg.game.forEach((ele) => {
                    totalwin += ele.win_statement_total;
                    totallose += ele.lose_statement_total;
                });
            }
            let shixun = _.find(res.msg.game, { game_id: "5b1f3a3cb76a591e7f25173" });
            let caipiao = _.find(res.msg.game, { game_id: "569a62be7ff123m117d446aa" });
            let shaba = _.find(res.msg.game, { game_id: "5b1f3a3cb76a591e7f25179" });
            //处理table需要的data
            setData([
                {
                    name: "棋牌流水数据",
                    win_statement_total: res.msg.game
                        ? totalwin -
                          (shixun?.win_statement_total || 0) -
                          (caipiao?.win_statement_total || 0) -
                          (shaba?.win_statement_total || 0)
                        : "",
                    lose_statement_total: res.msg.game
                        ? totallose -
                          (shixun?.lose_statement_total || 0) -
                          (caipiao?.lose_statement_total || 0) -
                          (shaba?.lose_statement_total || 0)
                        : "",
                },
                {
                    name: "视讯流水数据",
                    win_statement_total: shixun?.win_statement_total,
                    lose_statement_total: shixun?.lose_statement_total,
                },
                {
                    name: "彩票流水数据",
                    win_statement_total: caipiao?.win_statement_total,
                    lose_statement_total: caipiao?.lose_statement_total,
                },
                {
                    name: "沙巴流水数据",
                    win_statement_total: shaba?.win_statement_total,
                    lose_statement_total: shaba?.lose_statement_total,
                },
                {
                    name: "合计",
                    win_statement_total: totalwin,
                    lose_statement_total: totallose,
                },
            ]);
        } else {
            message.error(res.status || JSON.stringify(res));
        }
    };
    return (
        <Card
            title={
                <div>
                    <MyDatePicker
                        handleValue={(date, dateString) => {
                            setTimes({
                                start_time: date?.[0]?.valueOf(),
                                end_time: date?.[1]?.valueOf(),
                            });
                        }}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button type="primary" onClick={proxySearch}>
                        查看
                    </Button>
                </div>
            }
        >
            <Row gutter={[16, 16]} style={{ background: "#ececec", padding: 30 }}>
                <Col span={12}>
                    <Card>
                        <Statistic
                            title="代理链玩家总充值"
                            value={msg?.pay?.top_up_total || "--"}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Statistic
                            title="代理链玩家总提现"
                            value={msg?.pay?.withdraw_total || "--"}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Statistic title="充值人数" value={msg?.pay?.top_up_count || "--"} />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Statistic title="提现人数" value={msg?.pay?.withdraw_count || "--"} />
                    </Card>
                </Col>
            </Row>
            <br />
            <Table
                bordered
                rowKey={(record, index) => `${index}`}
                dataSource={data}
                columns={initColumns}
                size="small"
                style={{ background: "white" }}
                pagination={false}
            />
        </Card>
    );
};
