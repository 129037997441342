import React, { useState, useEffect, useRef } from "react";

import { Button, message, Select, Table, Modal, Popconfirm } from "antd";
import _ from "lodash-es";

import { GetSettlementUser } from "../../api";
import { gameRouter2, allgameid } from "../../functions/public_variable";
import { reverseNumber } from "../../functions/numberfn";

export default ({ parrent_record, detailData }) => {
    const [data, setdata] = useState(detailData);
    // console.log(parrent_record);
    // useEffect(() => {
    //     setdata(record)
    // }, [input])
    // useEffect(() => {
    //     console.log(record);
    //     let newdata = { ...data };
    //     _.forEach(record.detail, (v, k) => {
    //         _.forEach(newdata, (v1, k1) => {
    //             _.forEach(v1.child, (v2, k2) => {
    //                 if (v2.game_id === k) {
    //                     // console.log(v2.game_id, "===", k,"---------",newdata[k1].child[k2],"---------",v2,"-------",v);
    //                     newdata[k1].child[k2] = { ...v2, ...v };
    //                     // v2 = { ...v2, ...v };
    //                 }
    //             });
    //         });
    //     });
    //     console.log(newdata);
    //     setdata(newdata);
    //     // const fetchData = async () => {
    //     //     let res = await GetSettlementUser(record.id);
    //     //     if (res.code === 200 && res.msg) {
    //     //         res.msg.forEach((ele) => {
    //     //             _.forEach(allgameid, (v, k) => {
    //     //                 if (_.includes(v, ele.game_id)) {
    //     //                     newdata.forEach((v1) => {
    //     //                         if (k === v1.key) {
    //     //                             // v1.settlement_percent
    //     //                         }
    //     //                     });
    //     //                 }
    //     //             });
    //     //         });
    //     //     }
    //     // };
    // }, [newdata, record]);

    const initColumns = [
        {
            title: "游戏",
            dataIndex: "name",
        },
        {
            title: "玩家总赢",
            dataIndex: "win_total",
            render: (text, record) => {
                let res = reverseNumber(text);
                if (record.game_id === "569a62be7ff123m117d446aa") {
                    res = (reverseNumber(text) || 0) / 2 || "";
                }
                return res;
            },
        },
        {
            title: "玩家总输",
            dataIndex: "lose_total",
            render: (text, record) => {
                let res = reverseNumber(text);
                if (record.game_id === "569a62be7ff123m117d446aa") {
                    res = (reverseNumber(text) || 0) / 2 || "";
                }
                return res;
            },
        },
        {
            title: "总流水",
            dataIndex: "sum_water",
            render: (text, record) => {
                let res = reverseNumber(text);
                if (record.game_id === "569a62be7ff123m117d446aa") {
                    res = (reverseNumber(text) || 0) / 2 || "";
                }
                return res;
            },
        },
        {
            title: "总亏损",
            dataIndex: "abs_total",
            render: reverseNumber,
        },
        // {
        //     title: "用于计算的总亏损",
        //     dataIndex: "abs_total_forCaculate",
        //     render: reverseNumber,
        // },
        {
            title: "分红方式",
            dataIndex: "type",
            render: (text) => (text === 1 ? "按流水" : text === 2 ? "按亏损" : ""),
        },
        {
            title: "子游戏流水占比",
            dataIndex: "game_rate_water",
            render: (text) => text && (text * 100).toFixed(2) + "%",
        },
        {
            title: "子游戏亏损占比",
            dataIndex: "game_rate_abs",
            render: (text) => text && (text * 100).toFixed(2) + "%",
        },
        {
            title: "设置的百分比",
            dataIndex: "settlement_percent",
            render: (text) => text && text.toFixed(2) + "%",
        },
        // {
        //     title: "percent",
        //     dataIndex: "percent",
        //     render: (text) => text && text.toFixed(2) + "%",
        // },
        {
            title: "计算分红",
            dataIndex: "income1",
            render: reverseNumber,
        },
        {
            title: "实际分红",
            dataIndex: "income2",
            render: reverseNumber,
        },
        {
            title: "实际收入",
            dataIndex: "income3",
            render: reverseNumber,
        },
        {
            title: "档位",
            dataIndex: "grade_value",
            render: (text) => {
                return text && JSON.stringify(text);
            },
        },
        {
            title: "档位比",
            dataIndex: "grade_percent",
            render: (text) => {
                return text && JSON.stringify(text);
            },
        },
        // {
        //     title: "公式",
        //     dataIndex: "",
        // },
    ];
    return (
        <>
            {_.map(data, (v, k) => {
                return (
                    <div key={k}>
                        <div>{v.name}</div>
                        <Table
                            bordered
                            size="small"
                            rowKey="game_id"
                            dataSource={v.child}
                            columns={initColumns}
                            pagination={false}
                        />
                    </div>
                );
            })}
        </>
    );
};
