import ajax from "./ajax";
import axios from "axios";
import Promise_any from "p-any";
import { message } from "antd";

let BASE = localStorage.BASE || "";
let token = localStorage.token || "";
let account_name = parseInt(localStorage.getItem("id"));
//登陆成功后赋值token
export const setToken = () => {
    token = localStorage.token;
    account_name = parseInt(localStorage.getItem("id"));
};
export const setBASE = () => {
    BASE = localStorage.BASE;
};
//并发后台登录请求，判断最快节点
export const raceURL = () => {
    let URLs = process.env.REACT_APP_HOST.split(",");
    console.log(URLs);
    let promises = URLs.map((value) => {
        return axios.get(value + "/Proxy/User/getChildren");
    });
    Promise_any(promises)
        .then((result) => {
            localStorage.BASE = result.config.url.replace("/Proxy/User/getChildren", "");
            BASE = localStorage.BASE;
            console.log("选线成功版本2020.7.20", result, BASE);
        })
        .catch((error) => {
            message.error("网络状况不佳");
        });
};
// 登陆
export const reqLogin = ({ account_name, password }) =>
    ajax(BASE + "/Proxy/User/login", { account_name, password }, "POST");

//获取我的下级前两列
export const getChildren = (page, limit, account_name, id) =>
    ajax(
        BASE +
            `/Proxy/User/getChildren?id=${id}&account_name=${account_name}&page=${page}&limit=${limit}&token=${token}`,
        {},
        "GET",
    );
//获取我的下级第三列
export const getAggregation = (account_name, ids) =>
    ajax(
        BASE +
            `/Proxy/User/getAggregation?account_name=${account_name}&ids=[${ids}]&token=${token}`,
        {},
        "GET",
    );

//历史收益
export const getIncome = (page, limit, account_name, statement_level) =>
    ajax(
        BASE +
            `/Proxy/User/getProxyUserInductionListGroupByDate?account_name=${account_name}&page=${page}&limit=${limit}&statement_level=${statement_level}&token=${token}`,
        {},
        "GET",
    );

//累计贡献佣金
export const getAgentIncome = (page, limit, account_name, ids, date) =>
    ajax(
        BASE +
            `/Proxy/User/getProxyUserInductionListGroupByID?account_name=${account_name}&page=${page}&limit=${limit}&ids=[${ids}]&token=${token}&date=${date}`,
        {},
        "GET",
    );
export const getGameUserIncome = (page, limit, account_name, ids, date) =>
    ajax(
        BASE +
            `/Proxy/User/getGameUserInductionListGroupByID?account_name=${account_name}&page=${page}&limit=${limit}&ids=[${ids}]&token=${token}&date=${date}`,
        {},
        "GET",
    );
export const getProxychainData = (id, start_time, end_time) => {
    const account_name = parseInt(localStorage.getItem("id"));
    return ajax(
        BASE +
            `/Proxy/User/GetProxyLinkStatementAndPay?account_name=${account_name}&id=${id}&start_time=${start_time}&end_time=${end_time}&token=${token}`,
        {},
        "GET",
    );
};
//转账
export const transfermoney = (money, id) => {
    const account_name = parseInt(localStorage.getItem("id"));
    return ajax(
        BASE + `/proxy/user/moveBalanceToProxy`,
        { platform_key: 123456, account_name, token, id, money },
        "POST",
    );
};

//分红计算
export const AddSettlementGame = (game_id, settlement_type) => {
    return ajax(
        BASE + `/proxy/user/AddSettlementGame`,
        { account_name, token, game_id, settlement_type },
        "POST",
    );
};
export const DelSettlementGame = (game_id) => {
    return ajax(BASE + `/proxy/user/DelSettlementGame`, { account_name, token, game_id }, "POST");
};
export const SetSettlementGame = (game_id, settlement_type) => {
    return ajax(
        BASE + `/proxy/user/SetSettlementGame`,
        { account_name, token, game_id, settlement_type },
        "POST",
    );
};
export const GetSettlementGame = () => {
    return ajax(BASE + `/proxy/user/GetSettlementGame`, { account_name, token }, "GET");
};
export const SetSettlementUser = ({
    game_id,
    settlement_percent,
    grade_value,
    grade_percent,
    id,
}) => {
    // id = 0 表示所有
    // id != 0 表示指定ID
    // "game_id=5b1f3a3cb76a591e7f251714&settlement_percent=80&grade_value=[1000,2000,3000]&grade_percent=[10,40,80]&id=0"
    return ajax(
        BASE + `/proxy/user/SetSettlementUser`,
        { account_name, token, game_id, settlement_percent, grade_value, grade_percent, id },
        "POST",
    );
};
export const GetSettlementUser = (id) => {
    return ajax(BASE + `/proxy/user/GetSettlementUser`, { id, account_name, token }, "GET");
};
export const GetProxyLinkSettlementInfoByDate = ({ start_time, end_time }) => {
    // 不能包含当天
    // 以天为单位
    // "start_time=0&end_time=1594617627"
    return ajax(
        BASE + `/proxy/user/GetProxyLinkSettlementInfoByDate`,
        { account_name, token, start_time, end_time },
        "GET",
    );
};

export const GetProxyLinkChildrenSettlementInfoByDate = ({
    start_time,
    end_time,
    page,
    limit,
    settlement_info,
}) => {
    // 不能包含当天
    // 以天为单位
    //settlement_info 为/proxy/user/GetProxyLinkSettlementInfoByDate接口获取的信息
    // "start_time=0&end_time=1594617627&page=1&limit=10&settlement_info=[{"abs_total": -100,"win_total": 600,"game_id": "5b1f3a3cb76a591e7f251712","lose_total": -700},{"win_total": 400,"game_id": "5b1f3a3cb76a591e7f251714","lose_total": 0,"abs_total": 0}]"
    return ajax(
        BASE + `/proxy/user/GetProxyLinkChildrenSettlementInfoByDate`,
        { account_name, token, start_time, end_time, page, limit, settlement_info },
        "GET",
    );
};
export const RunProxyLinkChildrenSettlementInfoByDate = ({
    start_time,
    end_time,
    settlement_info,
    money,
}) => {
    // 不能包含当天
    // 以天为单位
    // settlement_info 为/proxy/user/GetProxyLinkSettlementInfoByDate接口获取的信息
    // money 为每个游戏ID对应的分账金额映射
    // "start_time=0&end_time=1594617627&settlement_info=[{"abs_total": -100,"win_total": 600,"game_id": "5b1f3a3cb76a591e7f251712","lose_total": -700},{"win_total": 400,"game_id": "5b1f3a3cb76a591e7f251714","lose_total": 0,"abs_total": 0}]&money={"5b1f3a3cb76a591e7f251714":1,"5b1f3a3cb76a591e7f251712":2}"
    return ajax(
        BASE + `/proxy/user/RunProxyLinkChildrenSettlementInfoByDate`,
        { account_name, token, start_time, end_time, settlement_info, money },
        "POST",
    );
};
