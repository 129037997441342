import React from "react";
import { Link, withRouter } from "react-router-dom";

import { Menu } from "antd";
import { MoneyCollectOutlined, UserOutlined } from "@ant-design/icons";
import "./index.css";

const LeftNav = (props) => {
	let path = props.location.pathname;
	console.log(path);
	
    return (
        <div className="left-nav">
            <div className="left-nav-header" onClick={() => (window.location.href = "/")}>
                <h1>全民代理后台系统</h1>
            </div>
            <Menu mode="inline" theme="dark" selectedKeys={[path]}>
                <Menu.Item
                    key="/myagent"
                    onClick={() => {
                        props.menu_onclick(1);
                    }}
                >
                    <Link to="/myagent">
                        <UserOutlined style={{ fontSize: 18 }} />
                        <span style={{ fontSize: 16 }}>我的下级</span>
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/income"
                    onClick={() => {
                        props.menu_onclick(2);
                    }}
                >
                    <Link to="/income">
                        <MoneyCollectOutlined style={{ fontSize: 18 }} />
                        <span style={{ fontSize: 16 }}>历史收益</span>
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/distribution"
                    onClick={() => {
                        props.menu_onclick(3);
                    }}
                >
                    <Link to="/distribution">
                        <MoneyCollectOutlined style={{ fontSize: 18 }} />
                        <span style={{ fontSize: 16 }}>分红计算</span>
                    </Link>
                </Menu.Item>
            </Menu>
        </div>
    );
};
export default withRouter(LeftNav);
