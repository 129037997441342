import React, { useState, useEffect, useRef } from "react";

import { Button, message, Card, Table, Modal, Input, Form, InputNumber } from "antd";
import moment from "moment";
import _ from "lodash-es";

import {
    GetSettlementGame,
    GetProxyLinkSettlementInfoByDate,
    GetProxyLinkChildrenSettlementInfoByDate,
    RunProxyLinkChildrenSettlementInfoByDate,
} from "../../api";
import { reverseNumber } from "../../functions/numberfn";
import { MyDatePicker } from "../../components/Mycomponents";
import Addgame from "./addgame";
import Proxychaindata from "../myagent/proxychaindata";
import DistributionDetail from "./distributionDetail";
import { gameRouter, gameRouter2, allgameid } from "../../functions/public_variable";
import DistributionSetting from "./distribution_setting";

export default () => {
    const [data, setData] = useState([]);
    const [childdata, setchilddata] = useState([]);
    const [childcount, setchildcount] = useState(0);
    const [is_addgame_show, set_is_addgame_show] = useState(false);
    const [is_distribution_detail_show, set_is_distribution_detail_show] = useState(false);
    // const [is_addgame_show, set_is_addgame_show] = useState(false);
    const [is_proxychaindata_show, set_is_proxychaindata_show] = useState(false);
    const [is_set_distribution_rate_show, set_is_set_distribution_rate_show] = useState(false);
    const [proxychaindata_id, set_proxychaindata_id] = useState();

    const account_name = localStorage.getItem("id");

    const init_ref = useRef({ start_time: null, end_time: null });
    const search_ref = useRef({ start_time: null, end_time: null });
    const refs = useRef({
        settlement_info: null,
        moneys: {},
        id: null,
        gameSet: null,
        detailData: {},
        record: null,
        total_water: { chesscardGame: 0, videoGame: 0, caipiaoGame: 0, shabaGame: 0 }, //四个类型游戏的四个总流水
        new_abs_total: { chesscardGame: 0, videoGame: 0, caipiaoGame: 0, shabaGame: 0 }, //四个类型的总盈亏
    });

    const fetchData = async () => {
        let res = await GetSettlementGame();
        if (res.code === 200) {
            refs.current.gameSet = res.msg;
        } else {
            message.error(JSON.stringify(res));
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const caculate_income = (record) => {
        let newdata = _.cloneDeep(gameRouter2);
        let total_income = 0;
        //按照game_id把record.detail(按游戏细分)的数据合并进去
        _.forEach(record.detail, (v, k) => {
            _.forEach(newdata, (v1, k1) => {
                _.forEach(v1.child, (v2, k2) => {
                    if (v2.game_id === k) {
                        newdata[k1].child[k2] = {
                            ...v2,
                            ...v,
                            ...v.config,
                            sum_water: v.win_total + Math.abs(v.lose_total), //总流水
                            abs_total_forCaculate: v.abs_total > 0 ? 0 : Math.abs(v.abs_total), //用于计算的亏损
                            game_rate_water: 0, //子游戏流水占比(玩家某一游戏流水/所有玩家该游戏流水)
                            game_rate_abs: 0, //子游戏亏损占比(玩家某一游戏亏损/所有玩家该游戏亏损)
                        };
                        //去GetProxyLinkSettlementInfoByDate返回的数据(存在refs.current.settlement_info)里找该游戏(不分玩家)总流水
                        _.forEach(refs.current.settlement_info, (v3, k3) => {
                            if (v3.game_id === k) {
                                let game_rate_water = refs.current.total_water[k1]
                                    ? (v.win_total + Math.abs(v.lose_total)) /
                                      (refs.current.total_water[k1] || 1)
                                    : 0;
                                let game_rate_abs = refs.current.new_abs_total[k1]
                                    ? Math.abs(
                                          (v.abs_total > 0 ? 0 : v.abs_total) /
                                              refs.current.new_abs_total[k1],
                                      )
                                    : 0;
                                let income_for_caculate =
                                    v.config.type === 1
                                        ? v.win_total + Math.abs(v.lose_total)
                                        : v.config.type === 2
                                        ? v.abs_total > 0
                                            ? 0
                                            : Math.abs(v.abs_total)
                                        : 0;
                                //这里用new_abs_total而不是abs_total,new_abs_total是所有带来盈利的下级盈利和,abs_total包括了亏损的下级
                                let incomerate_for_caculate =
                                    v.config.type === 1
                                        ? game_rate_water
                                        : v.config.type === 2
                                        ? game_rate_abs
                                        : 0;
                                let income1 =
                                    incomerate_for_caculate * (refs.current.moneys?.[k1] || 0);
                                let income2 = (income1 * v.config.settlement_percent) / 100;
                                let index, income3;
                                if (v.config.grade_value && v.config.grade_percent) {
                                    if (_.includes(v.config.grade_value, income_for_caculate)) {
                                        index = _.indexOf(
                                            v.config.grade_value,
                                            income_for_caculate,
                                        );
                                    } else {
                                        index = _.sortedIndex(
                                            v.config.grade_value,
                                            income_for_caculate,
                                        );
                                    }
                                    income3 =
                                        index !== -1
                                            ? (v.config.grade_percent?.[index] / 100 || 1) * income2
                                            : 0;
                                } else {
                                    income3 = income2;
                                }
                                total_income += income3;
                                _.assign(newdata[k1].child[k2], {
                                    a: v.config.grade_percent?.[index] / 100,
                                    game_rate_water,
                                    game_rate_abs,
                                    incomerate_for_caculate,
                                    income1,
                                    income2,
                                    income3,
                                    index,
                                });
                            }
                        });
                    }
                });
            });
        });
        refs.current.detailData[record.id] = newdata;
        return total_income;
    };
    const check_child_distribution = async (page, limit) => {
        let { start_time, end_time } = search_ref.current;
        //初始化refs中计算的值
        refs.current.total_water = { chesscardGame: 0, videoGame: 0, caipiaoGame: 0, shabaGame: 0 };
        refs.current.new_abs_total = {
            chesscardGame: 0,
            videoGame: 0,
            caipiaoGame: 0,
            shabaGame: 0,
        };
        let { settlement_info, total_water, new_abs_total } = refs.current;
        if (!refs.current.settlement_info) {
            message.error("分红计算失败, 请检查参与计算的游戏设置");
            return;
        }
        let res = await GetProxyLinkChildrenSettlementInfoByDate({
            start_time,
            end_time,
            page,
            limit,
            settlement_info: JSON.stringify(refs.current.settlement_info),
        });
        if (res.code === 200) {
            message.success("请求成功");
            let arr = [];
            //计算total_water和new_abs_total
            _.forEach(res.msg, (v) => {
                _.forEach(v, (v0, k0) => {
                    _.forEach(allgameid, (v1, k1) => {
                        if (_.includes(v1, k0)) {
                            total_water[k1] += v0.win_total + Math.abs(v0.lose_total);
                            new_abs_total[k1] += v0.abs_total > 0 ? 0 : Math.abs(v0.abs_total);
                        }
                    });
                });
            });
            console.log("总流水和总亏损", total_water, new_abs_total);
            _.forEach(res.msg, (v1, k1) => {
                let abs_total = 0,
                    win_total = 0,
                    lose_total = 0;
                _.forEach(v1, (v2, k2) => {
                    if (k2 !== "569a62be7ff123m117d446aa") {
                        abs_total += v2.abs_total;
                        win_total += v2.win_total;
                        lose_total += v2.lose_total;
                    } else {
                        abs_total += v2.abs_total;
                        win_total += v2.win_total / 2;
                        lose_total += v2.lose_total / 2;
                    }
                });
                let record = { id: k1, abs_total, win_total, lose_total, detail: v1 };
                let total_income = caculate_income(record);
                record.total_income = total_income;
                arr.push(record);
            });

            console.log("计算完毕", refs.current.detailData);
            setchilddata(arr);
        } else {
            message.error(JSON.stringify(res));
        }
    };
    const check_distribution = async (value) => {
        console.log(value);
        let { start_time, end_time } = init_ref.current;
        if (!start_time || !end_time) {
            message.error("请选择时间");
            return;
        }
        refs.current.moneys = value;
        search_ref.current = init_ref.current;
        let res = await GetProxyLinkSettlementInfoByDate({ start_time, end_time });
        if (res.code === 200) {
            refs.current.settlement_info = res.msg;
        } else {
            message.error(JSON.stringify(res));
        }
        check_child_distribution(1, 50);
    };
    const tablebtn_handled = (record, btn) => {
        //record包含该玩家选定时间段内每个游戏的流水,以及总流水,总win,总lose
        console.log("tablebtn_handled!!!", record);
        refs.current.id = record.id;
        if (btn === 1) {
            set_is_distribution_detail_show(true);
        }
        if (btn === 2) set_is_set_distribution_rate_show(true);
    };

    const transfermoney = async () => {
        let { settlement_info, moneys, gameSet } = refs.current;
        let money = {};
        if (!settlement_info) {
            message.error("没有设置分红配置");
            return;
        }
        let obj = _.pickBy(moneys, _.identity);
        if (_.isEmpty(obj)) {
            message.error("请输入分红金额");
            return;
        }
        if (!gameSet) {
            message.error("请设置分红类型");
            return;
        }
        if (moneys.chesscardGame) {
            let sumwater = 0,
                sumincome = 0;
            settlement_info.forEach((ele1) => {
                gameSet.forEach((ele2) => {
                    if (ele1.game_id === ele2.game_id) {
                        ele1.type = ele2.type;
                    }
                });
                if (_.includes(JSON.stringify(gameRouter.chesscardGame), ele1.game_id)) {
                    ele1.water = ele1.win_total + Math.abs(ele1.lose_total);
                    ele1.new_abs_total = ele1.abs_total > 0 ? 0 : Math.abs(ele1.abs_total);
                    //求总流水和总盈亏
                    sumwater += ele1.water;
                    sumincome += Math.abs(ele1.new_abs_total);
                }
            });
            console.log("用于money计算的总流水和总盈亏", sumwater, sumincome);
            settlement_info.forEach((e) => {
                if (_.includes(JSON.stringify(gameRouter.chesscardGame), e.game_id)) {
                    e.waterrate = e.water / sumwater;
                    e.incomerate = e.new_abs_total / sumincome;

                    e.money =
                        e.type === 1
                            ? e.waterrate * moneys.chesscardGame
                            : e.type === 2
                            ? e.incomerate * moneys.chesscardGame
                            : 0;
                    money[e.game_id] = e.money;
                }
            });
        }
        money["5b1f3a3cb76a591e7f25173"] = moneys.videoGame || 0;
        money["569a62be7ff123m117d446aa"] = moneys.caipiaoGame || 0;
        money["5b1f3a3cb76a591e7f25179"] = moneys.shabaGame || 0;
        console.log("-------------------money:", money);
        let { start_time, end_time } = search_ref.current;
        let res = await RunProxyLinkChildrenSettlementInfoByDate({
            start_time,
            end_time,
            settlement_info: JSON.stringify(settlement_info),
            money: JSON.stringify(money),
        });
        if (res.code === 200) {
            message.success(`操作成功`);
        } else {
            message.error(res.msg || "操作失败");
        }
    };
    const throttled = _.throttle(transfermoney, 2000, { trailing: false });
    const transfermoeny_handled = () => {
        Modal.confirm({
            title: "一键转账",
            okText: "确定",
            cancelText: "取消",
            content: (
                <>
                    <div>确定要一键转账吗?</div>
                    <pre>{JSON.stringify(refs.current.moneys, null, 2)}</pre>
                </>
            ),
            width: "40%",
            onOk: throttled,
        });
    };
    const childcolumns = [
        {
            title: "直属下级",
            dataIndex: "id",
        },
        {
            title: "玩家总赢",
            dataIndex: "win_total",
            render: reverseNumber,
        },
        {
            title: "玩家总输",
            dataIndex: "lose_total",
            render: reverseNumber,
        },
        {
            title: "总流水",
            dataIndex: "",
            render: (text, record) => reverseNumber(record.win_total + Math.abs(record.lose_total)),
        },
        {
            title: "总盈亏",
            dataIndex: "abs_total",
            render: reverseNumber,
        },
        {
            title: "分红金额",
            dataIndex: "total_income",
            render: reverseNumber,
        },
        {
            title: "操作",
            dataIndex: "",
            render: (text, record) => {
                return (
                    <>
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => tablebtn_handled(record, 1)}
                        >
                            分红明细
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => tablebtn_handled(record, 2)}
                        >
                            比例修改
                        </Button>
                    </>
                );
            },
        },
    ];
    return (
        <Card>
            <Button
                type="primary"
                onClick={() => {
                    set_is_addgame_show(true);
                }}
            >
                参与计算的游戏设定
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
                type="primary"
                onClick={() => {
                    set_proxychaindata_id(account_name);
                    set_is_proxychaindata_show(true);
                }}
            >
                代理链数据
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontSize: 16 }}>
                佣金余额&nbsp;:&nbsp;{reverseNumber(localStorage.balance)}
            </span>
            <br />
            <br />
            <div>
                <MyDatePicker
                    handleValue={(date, dateString) => {
                        if (dateString) {
                            console.log(
                                moment(`${dateString[0]} 00:00:00`).valueOf(),
                                moment(`${dateString[1]} 23:59:59`).valueOf(),
                            );
                            init_ref.current = {
                                start_time: moment(`${dateString[0]} 00:00:00`).valueOf() / 1000,
                                end_time: moment(`${dateString[1]} 23:59:59`).valueOf() / 1000,
                            };
                        } else {
                            init_ref.current = {
                                start_time: null,
                                end_time: null,
                            };
                        }
                    }}
                    showTime={false}
                    disabledDate={(current) => current && current >= moment().startOf("day")}
                    ranges={{
                        昨天: [
                            moment().startOf("day").subtract(1, "days"),
                            moment().endOf("day").subtract(1, "days"),
                        ],
                        最近一周: [
                            moment().startOf("day").subtract(1, "weeks"),
                            moment().endOf("day").subtract(1, "days"),
                        ],
                    }}
                />
                &nbsp;&nbsp;&nbsp;
            </div>
            <br />
            <div>
                <Form onFinish={check_distribution} layout="inline">
                    <Form.Item label="棋牌类子游戏" name="chesscardGame">
                        <InputNumber style={{ width: 120 }} placeholder="分红金额" />
                    </Form.Item>
                    <Form.Item label="视讯类子游戏" name="videoGame">
                        <InputNumber style={{ width: 120 }} placeholder="分红金额" />
                    </Form.Item>
                    <Form.Item label="彩票类子游戏" name="caipiaoGame">
                        <InputNumber style={{ width: 120 }} placeholder="分红金额" />
                    </Form.Item>
                    <Form.Item label="沙巴子游戏" name="shabaGame">
                        <InputNumber style={{ width: 120 }} placeholder="分红金额" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            分红计算
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <br />
            <Table
                bordered
                size="small"
                rowKey="id"
                dataSource={childdata}
                columns={childcolumns}
                pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: 50,
                    // total: childcount,
                    // showQuickJumper: true,
                    // showSizeChanger: true,
                    // showTotal: (total, range) => `共${total}条`,
                    // onChange: (page, pageSize) => {
                    //     check_child_distribution(page, pageSize);
                    // },
                    // onShowSizeChange: (current, size) => {
                    //     this.setState({
                    //         pageSize: size,
                    //     });
                    //     this.getUsers(1, 20);
                    // },
                }}
            />
            <Button
                type="primary"
                style={{ width: 300, display: "block", margin: "0 auto" }}
                onClick={transfermoeny_handled}
            >
                一键转账
            </Button>
            {is_addgame_show && (
                <Modal
                    title={`参与计算的游戏`}
                    visible={is_addgame_show}
                    onCancel={() => {
                        window.location.reload();
                    }}
                    footer={null}
                    width="60%"
                    mask
                    maskClosable={false}
                >
                    <Addgame />
                </Modal>
            )}
            {is_proxychaindata_show && (
                <Modal
                    title={`[ID:${proxychaindata_id}]代理链数据`}
                    visible={is_proxychaindata_show}
                    onCancel={() => {
                        set_is_proxychaindata_show(false);
                    }}
                    footer={null}
                    mask={false}
                    maskClosable={false}
                    width="60%"
                    style={{ top: 10 }}
                >
                    <Proxychaindata
                        id={proxychaindata_id}
                        // timerange={timerange.current}
                    />
                </Modal>
            )}
            {is_distribution_detail_show && (
                <Modal
                    title={`${refs.current.id}分红明细`}
                    visible={is_distribution_detail_show}
                    onCancel={() => set_is_distribution_detail_show(false)}
                    footer={null}
                    width="90%"
                    mask
                    maskClosable={false}
                    style={{ top: 10 }}
                >
                    <DistributionDetail
                        detailData={refs.current.detailData[refs.current.id]}
                        parrent_record={refs.current.record}
                    />
                </Modal>
            )}
            {is_set_distribution_rate_show && (
                <Modal
                    title={`${refs.current.id}分红比例设置`}
                    visible={is_set_distribution_rate_show}
                    onCancel={() => set_is_set_distribution_rate_show(false)}
                    footer={null}
                    width="60%"
                    mask
                    maskClosable={false}
                >
                    <Addgame id={refs.current.id} is_tablebtn_clicked={true} />
                </Modal>
            )}
        </Card>
    );
};
